import { bool, func, node, object, shape, string } from 'prop-types';
import React from 'react';

import SectionHeroDefault from './SectionHeroDefault';
import SectionHeroWithSearch from './SectionHeroWithSearch';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionHeroCustom = props => {
  const { sectionName } = props;
  if (/^hero-search-box/.test(sectionName)) {
    return <SectionHeroWithSearch {...props} />;
  }

  return <SectionHeroDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHeroCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHeroCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHeroCustom;
