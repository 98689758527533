import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { OutsideClickHandler } from '../../../../components';
import LocationAutocompleteInputImpl from '../../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';
import { types as sdkTypes } from '../../../../util/sdkLoader';

import css from './BlockHeroWithSearch.module.css';

const { LatLng, LatLngBounds } = sdkTypes;

const LocationAutocompletePanel = ({ onChange, autoFocus, value }) => {
  return (
    <div className={css.locationAutocompletePanel}>
      <LocationAutocompleteInputImpl
        className={css.locationAutocomplete}
        iconClassName={css.locationAutocompleteIcon}
        inputClassName={css.locationAutocompleteInput}
        predictionsClassName={css.locationAutocompletePredict}
        autoFocus={autoFocus}
        placeholder={'Search spaces...'}
        input={{
          value: value,
          id: 'location-input',
          name: 'location',
          onChange,
          onFocus: () => null,
          onBlur: () => null,
        }}
      />
    </div>
  );
};

const SearchForm = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const [whereValue, setWhereValue] = useState(null);
  const [isOpenPanel, setIsOpenPanel] = useState(false);

  const handleOpenPanel = index => () => {
    setIsOpenPanel(isOpenPanel === index ? false : index);
  };

  const handleSetPrediction = value => {
    setWhereValue(value);

    document.getElementById("location-input").focus();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    const { address, bounds } = whereValue?.selectedPlace || {};
    const searchParams = {
      address: address || 'United Kingdom',
      bounds:
        bounds ||
        new LatLngBounds(new LatLng(60.9093508, 1.8623843), new LatLng(49.80777, -8.7497874)),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  return (
    <div className={css.searchBox}>
      <form className={css.searchBoxItem} onSubmit={handleSubmit}>
        <OutsideClickHandler
          className={css.searchBoxItemOutsideClick}
          onOutsideClick={() => {
            if (isOpenPanel === 1) {
              setIsOpenPanel(false);
            }
          }}
        >
          <label
            htmlFor="location-input"
            className={css.searchBoxItemContent}
            onClick={handleOpenPanel(1)}
          >
            <svg
              width="29"
              height="30"
              viewBox="0 0 29 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleSubmit}
            >
              <circle cx="16.556" cy="11.9436" r="10.129" stroke="#D7FF5A" strokeWidth="3.11661" fill='none' />
              <path
                d="M0.650064 27.204C0.0415077 27.8126 0.0415077 28.7992 0.650064 29.4078C1.25862 30.0164 2.24529 30.0164 2.85384 29.4078L0.650064 27.204ZM2.85384 29.4078L10.6454 21.6163L8.4416 19.4125L0.650064 27.204L2.85384 29.4078Z"
                fill="#D7FF5A"
              />
            </svg>
            <LocationAutocompletePanel onChange={handleSetPrediction} value={whereValue} />
          </label>
        </OutsideClickHandler>
      </form>
    </div>
  );
};

export default SearchForm;
